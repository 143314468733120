import React, {
  useState, useEffect
} from 'react'

import { OrderBtn } from 'src/features/basket/BasketBtn'
import { getBasketProducts } from 'src/features/basket/selectors'
import { useBasket } from 'src/features/basket/useBasket'
import { BackButton } from 'src/features/catalog/BackButton'
import { Counter } from 'src/ui/Counter'
import {
  InputGroupIOS, InputIOS
} from 'src/ui/InputIOS'
import { Switch } from 'src/ui/Switch'
import { extractImgUrl } from 'src/utils/extractors'
import styled from 'styled-components'
import { catalogApi } from 'src/features/api'
import {
  getBranchClosingStatus,
  getDeliveryAddress, getUserId
} from 'src/features/app/selectors'
import { CheckboxSelectIOS } from 'src/ui/CheckboxSelectIOS'
import { MIN_ORDER_AMOUNT } from 'src/constants'
import utensilsImg from 'src/images/utensils.png'
import plural from 'plural-ru'
import { getBotSettings } from 'src/features/catalog/selectors'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'

import { ReactComponent as TrashIcon } from '@/images/icons/trash.svg'

import { Button } from '../ui/Button'
import { formatPrice } from '../utils/formatters'

export const BasketPage = ({ className = '' }) => {
  const products = getBasketProducts()
  const userId = getUserId()
  const deliveryAddress = getDeliveryAddress()
  const isShowNumberUtensils = getBotSettings()?.isShowNumberUtensils
  const isBranchClosed = getBranchClosingStatus()

  const [paymentTypes, setPaymentTypes] = useState([])
  const [currentPaymentType, setCurrentPaymentType] = useState(null)
  const [utensilsCount, setUtensilsCount] = useState(products.length || 1)

  const [withDelivery, toggleDelivery] = useState(true)
  const [withDiscountCard, toggleDiscountCard] = useState(false)
  const [userData, setUserData] = useState({
    name: '',
    phone: ''
  })
  const [deliveryData, setDeliveryData] = useState({
    city: process.env.REACT_APP_SINGLE_CITY || '',
    street: '',
    house: '',
    appartment: '',
    floor: '',
    approach: ''
  })

  const [comment, setComment] = useState('')
  const [promocode, setPromocode] = useState('')
  const [discountCard, setDiscountCard] = useState('')
  const [deliveryInfo, setDeliveryInfo] = useState(null)
  const [requestInProcess, toggleRequestProcess] = useState(false)

  const [promocodeData, setPromocodeData] = useState({})
  const [loyaltyCardData, setLoyaltyCardData] = useState({})

  const amountFromProducts = products.reduce((r, p) => r += p.price * (p.count || 1), 0)
  const discountWithPromocode = (promocodeData?.amount - promocodeData?.afterDiscount) || 0
  const discountWithLoyaltyCard = loyaltyCardData?.type === 'discount' ? amountFromProducts * loyaltyCardData?.amount / 100 : 0
  const amount = promocodeData?.afterDiscount || amountFromProducts - discountWithLoyaltyCard
  const discount = products.reduce((r, p) => r += (p.price - (p.old_price || p.price)) * (p.count || 1), 0) - discountWithPromocode - discountWithLoyaltyCard

  const {
    addProduct, removeProduct, removeAll
  } = useBasket()

  useEffect(() => {
    if (deliveryAddress?.address) {
      const {
        city, appartment, floor, house, street, approach
      } = deliveryAddress?.address

      setDeliveryData({
        city,
        appartment,
        floor,
        house,
        street,
        approach
      })
    }

    if (deliveryAddress?.info) {
      const {
        name, phone
      } = deliveryAddress?.info

      setUserData({
        name, phone
      })
    }
  }, [deliveryAddress])

  useEffect(async () => {
    const response = await catalogApi.getPaymentsMethods()

    setPaymentTypes(response.data.map(payment => ({
      label: payment.name, value: payment.name, img: payment?.image?.url
    })))

    setCurrentPaymentType(response.data.find(payment => payment.default).name)
  }, [])

  useEffect(async () => {
    const response = await catalogApi.getDeliveryInfo()

    setDeliveryInfo(response.data)
  }, [])

  const handleAddProductClick = (data) => {
    addProduct(data)
  }

  const handleRemoveProductClick = (data, full = false) => {
    removeProduct(data, full)
  }

  const handleAddUtensils = () => {
    if (utensilsCount > 10) {
      return
    }

    setUtensilsCount(state => state + 1)
  }

  const handleRemoveUtensils = () => {
    if (utensilsCount <= 0) {
      return
    }

    setUtensilsCount(state => state - 1)
  }

  const handleDeliveryDataChange = (e, field) => {
    const regexp = /[^0-9-a-zа-яё_\-\/=\s]/gi
    const value = e.target.value
    setDeliveryData(state => ({
      ...state, [field]: value.replace(regexp, '').substring(0, 25)
    }))
  }

  const handleToggleDiscountCard = (checked) => {
    toggleDiscountCard(checked)

    if (!checked) {
      resetLoyaltyCardMutation()
      setLoyaltyCardData({})
    }
  }

  const handleCreateOrder = async () => {
    toggleRequestProcess(true)

    const data = {
      userId,
      userData,
      deliveryData: withDelivery ? deliveryData : null,
      comment,
      promocode,
      discountCard,
      gateway_choice: currentPaymentType
    }

    if (isShowNumberUtensils) {
      data.numberUtensils = utensilsCount
    }

    const response = await catalogApi.createOrder({ data })

    if (response.results !== 'err') {
      window?.Telegram?.WebApp?.close()
    }

    toggleRequestProcess(false)
  }

  const {
    isLoading: promocodeInProgress, mutate: handleApplyPromocode, error: promocodeError, isSuccess: promocodeSuccess
  } = useMutation({
    mutationFn: async () => {
      const response = await catalogApi.applyPromocode({
        data: {
          userId: userId,
          promocode
        }
      })

      setPromocodeData(response?.data?.attributes)
    },
  })

  const {
    isLoading: loyaltyCardInProgress, mutate: handleApplyLoyaltyCard, error: loyaltyCardError, isSuccess: loyaltyCardSuccess, reset: resetLoyaltyCardMutation
  } = useMutation({
    mutationFn: async () => {
      const response = await catalogApi.applyLoyaltyCard({
        userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 229165046, number: discountCard
      })

      if (response?.data) {
        setLoyaltyCardData(response?.data)

        if (response.data.type === 'discount') {
          setPromocodeData({})
        }
      }
    },
  })

  const btnDisabled = isBranchClosed || !userData.name || !userData.phone || requestInProcess ||
  (withDelivery && (!deliveryData.city || !deliveryData.street || !deliveryData.house)) || amount < MIN_ORDER_AMOUNT

  return (
    <BasketPageBox className={className}>
      <TopRow>
        <BackButton />

        <Title>Корзина</Title>

        <TopRowEmptyDiv />
      </TopRow>

      {isBranchClosed &&
        <Closed>
      Кафе сейчас закрыто. <br />
      Ждём вас завтра.
        </Closed>
      }

      <OrderBox>
        <TitleRow>
          <Title>{products.length} {plural(products.length, 'товар', 'товара', 'товаров')} на {formatPrice(amount)} ₽</Title>

          <RemoveAllBtn onClick={removeAll}>Удалить все</RemoveAllBtn>
        </TitleRow>

        {
          products.map(product => (
            <ProductRow key={product.id}>
              <ProductImgBox>
                <ProductImg src={extractImgUrl(product.images?.data?.[0])} />
              </ProductImgBox>

              <ProductInfo>
                <ProductTitle>{product.name}</ProductTitle>

                <PriceRow>
                  <Price>{formatPrice(product.price)} ₽</Price>
                  {!!product.old_price &&
                    <OldPrice>{formatPrice(product.old_price)} ₽</OldPrice>
                  }

                  <StyledCounter
                    size="small"
                    count={product.count}
                    onPlus={() => handleAddProductClick(product)}
                    onMinus={() => handleRemoveProductClick(product)}
                  />
                </PriceRow>

                <ProductDescription>
                  {product.orderParams}
                </ProductDescription>
              </ProductInfo>
            </ProductRow>
          ))
        }

        {
          !!products.length && isShowNumberUtensils &&
          <ProductRow>
            <ProductImgBox>
              <ProductImg src={utensilsImg} />
            </ProductImgBox>

            <ProductInfo>
              <ProductTitle>Приборы</ProductTitle>

              <PriceRow>
                <Price>Бесплатно</Price>

                <StyledCounter
                  size="small"
                  count={utensilsCount}
                  onPlus={handleAddUtensils}
                  onMinus={handleRemoveUtensils}
                />
              </PriceRow>
            </ProductInfo>
          </ProductRow>
        }

        <ProductsInfo>
          <ProductsInfoRow>
            Товаров в заказе ({products.length})

            <ProductsInfoAmount>{formatPrice(amount)} ₽</ProductsInfoAmount>
          </ProductsInfoRow>

          <ProductsInfoRow>
            Скидка

            <ProductsInfoAmount>{formatPrice(discount)} ₽</ProductsInfoAmount>
          </ProductsInfoRow>
        </ProductsInfo>

        <TotalRow>
          Итого:
          <span>{formatPrice(amount)} ₽</span>
        </TotalRow>
      </OrderBox>

      <InputGroupLabel>
        Покупатель
      </InputGroupLabel>
      <StyledInputGroupIOS>
        <InputIOS
          label="Имя"
          value={userData.name}
          onChange={(e) => {
            const regexp = /[^a-zа-яё_\-\/=\s]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              name: value.replace(regexp, '').substring(0, 25)
            }))}}
        />
        <InputIOS
          label="Телефон"
          value={userData.phone}
          mask="+79999999999"
          onChange={(e) => {
            const regexp = /[^0-9]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              phone: '+' + value.replace(regexp, '').substring(0, 11).trim()
            }))}}
        />
      </StyledInputGroupIOS>

      <InputGroupLabel>
        Промокод
      </InputGroupLabel>
      <PromocodeBox>
        <PromocodeInput
          placeholder="Промокод"
          value={promocode}
          onChange={(e) => setPromocode(e.target.value)}
          className={classNames({ error: !!promocodeError })}
        />

        <StyledPromocodeBtn
          disabled={promocodeInProgress || promocodeSuccess || loyaltyCardData.type === 'discount'}
          onClick={handleApplyPromocode}
        >
          Применить
        </StyledPromocodeBtn>
      </PromocodeBox>

      {
        !!promocodeError &&
        <StyledPromocodeText>
          {promocodeError?.error?.message || 'Промокод не найден'}
        </StyledPromocodeText>
      }

      {
        !!promocodeSuccess &&
        <StyledPromocodeText>
          Промокод применен
        </StyledPromocodeText>
      }

      <LoyaltyCardRow className={classNames({ opened: withDiscountCard })}>
        Карта лояльности

        <Switch
          checked={withDiscountCard}
          onChange={handleToggleDiscountCard}
        />

        {withDiscountCard &&
          <LoyaltyCardInputBox>
            <LoyaltyCardInput
              placeholder="Ввести номер карты"
              value={discountCard}
              onChange={e => setDiscountCard(e.target.value)}
            />
            <StyledLoyaltyCardBtn
              onClick={handleApplyLoyaltyCard}
              disabled={loyaltyCardInProgress || (loyaltyCardSuccess && !!loyaltyCardData?.type)}
            >
              Применить
            </StyledLoyaltyCardBtn>
          </LoyaltyCardInputBox>
        }
      </LoyaltyCardRow>

      {
        (!!loyaltyCardError || (!!loyaltyCardSuccess && !loyaltyCardData?.type)) &&
        <StyledPromocodeText>
          {loyaltyCardError?.error?.message || 'Карта не найдена'}
        </StyledPromocodeText>
      }

      {
        !!loyaltyCardSuccess && loyaltyCardData?.type &&
        <StyledPromocodeText>
          Карта применена
        </StyledPromocodeText>
      }

      <InputGroupLabel>
        Комментарий
      </InputGroupLabel>
      <StyledInputGroupIOS>
        <Textarea
          placeholder={process.env.REACT_APP_ORDER_COMMENT_PLACEHOLDER || 'Комментарий'}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </StyledInputGroupIOS>

      <DeliveryRow>
        Доставка

        <Switch
          checked={withDelivery}
          onChange={toggleDelivery}
        />
      </DeliveryRow>

      {
        withDelivery &&
        <>
          {
            !!deliveryInfo?.min_delivery_price &&
          <DeliveryRow>
            Минимальная стоимость доставки

            <span>{deliveryInfo?.min_delivery_price} ₽</span>
          </DeliveryRow>
          }
          {deliveryInfo &&
            <DeliveryInfoBox>
              <DeliveryImg src={deliveryInfo?.delivery_info_image?.url} />
              <DeliveryInfo>
                {deliveryInfo?.delivery_info}
              </DeliveryInfo>
            </DeliveryInfoBox>
          }

          <InputGroupLabel>
            Адрес доставки
          </InputGroupLabel>
          <StyledInputGroupIOS>
            <InputIOS
              label="Город"
              value={deliveryData.city}
              disabled={!!process.env.REACT_APP_SINGLE_CITY}
              onChange={(e) => handleDeliveryDataChange(e, 'city')}
            />
            <InputIOS
              label="Улица"
              value={deliveryData.street}
              onChange={(e) => handleDeliveryDataChange(e, 'street')}
            />
            <InputIOS
              label="Дом"
              value={deliveryData.house}
              onChange={(e) => handleDeliveryDataChange(e, 'house')}
            />
            <InputIOS
              label="Подъезд"
              value={deliveryData.approach}
              onChange={(e) => handleDeliveryDataChange(e, 'approach')}
            />
            <InputIOS
              label="Квартира"
              value={deliveryData.appartment}
              onChange={(e) => handleDeliveryDataChange(e, 'appartment')}
            />
            <InputIOS
              label="Этаж"
              value={deliveryData.floor}
              onChange={(e) => handleDeliveryDataChange(e, 'floor')}
            />
          </StyledInputGroupIOS>
        </>
      }

      <InputGroupLabel>
        Вид оплаты
      </InputGroupLabel>

      <StyledCheckboxSelectIOS
        checked={currentPaymentType}
        options={paymentTypes}
        onChange={setCurrentPaymentType}
      />

      <Rules>
        Нажимая на кнопку «Сформировать заказ», я принимаю <a href="#">пользовательское соглашение</a> и <a href="#">политику конфиденциальности</a>
      </Rules>

      <OrderBtn
        text={`Оформить заказ - ${formatPrice(amount)} ₽`}
        disabled={btnDisabled}
        onClick={handleCreateOrder}
      />
    </BasketPageBox>
  )
}

const BasketPageBox = styled.div`
  background: ${props => props.theme.backgrounds.secondary};
  min-height: 100vh;
  padding: 16px 8px 85px;
`

const OrderBox = styled.div`
  width: 100%;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  padding: 16px;
`

const Title = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.8px;
  color: ${props => props.theme.text.main};
`

const ProductRow = styled.div`
  display: flex;

  & ~ & {
    margin-top: 24px;
  }
`

const ProductImgBox = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 4px;
`

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ProductInfo = styled.div`
  margin-left: 16px;
  width: 100%;
`

const ProductTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.text.main};
`

const PriceRow = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const OldPrice = styled.p`
  margin-left: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.4px;
  display: flex;
  align-items: flex-end;
  text-decoration-line: line-through;
  color: ${props => props.theme.text.quaternary};
`

const Price = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.8px;
  color: ${props => props.theme.text.main};
`

const ProductDescription = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const TotalRow = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 5px;
  border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const ProductsInfo = styled.div`
  margin-top: 15px;
  padding: 23px 0 5px;
  border-top: 0.33px solid rgba(60, 60, 67, 0.29);
`

const ProductsInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};

  & ~ & {
    margin-top: 24px;
  }
`

const ProductsInfoAmount = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const TopRow = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TopRowEmptyDiv = styled.div`
  width: 32px;
`

const StyledInputGroupIOS = styled(InputGroupIOS)`
  
`

const InputGroupLabel = styled.p`
  margin-top: 28px;
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  padding-left: 16px;
  color: #636366;
`

const DeliveryRow = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const DeliveryInfoBox = styled.div`
  margin-top: 24px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  padding: 16px;
`

const DeliveryImg = styled.img`
  width: 100%;
`

const DeliveryInfo = styled.p`
  margin-top: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.text.main};
`

const StyledCounter = styled(Counter)`
  margin-left: auto;
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`

const RemoveAllBtn = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.text.tertiary};
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`

const ControlsRow = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RemoveProduct = styled.div`
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: ${props => props.theme.text.main};
`

const StyledTrashIcon = styled(TrashIcon)`
  margin-right: 10px;

  path {
    fill: ${props => props.theme.text.main};
  }
`

const StyledCheckboxSelectIOS = styled(CheckboxSelectIOS)`

`

const Rules = styled.p`
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
  padding: 0 5px;

  a {
    color: ${props => props.theme.text.tertiary};
    text-decoration: underline;
  }
`

const Textarea = styled.textarea`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.inputs.color};
  border: none;
  padding: 10px 15px;
  /* border-top: 0.33px solid rgba(60, 60, 67, 0.29); */
  width: 100%;
  outline: none;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.main};

  &::placeholder {
    color: ${props => props.theme.inputs.placeholder};
  }
`

const Closed = styled.div`
  margin-bottom: 24px;
  width: 100%;
  height: 112px;
  border-radius: 16px;
  background: ${props => props.theme.backgrounds.main};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.text.main};
`

const PromocodeBox = styled.div`
  width: 100%;
  height: 42px;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.main};
  display: flex;
  align-items: center;
  overflow: hidden;
`

const PromocodeInput = styled.input`
  flex: 1;
  height: 100%;
  padding: 0 16px;
  border: none;
  outline: none;
  color: ${props => props.theme.inputs.color};
  background: transparent;
  font-weight: 400;
  font-size: 17px;

  &::placeholder {
    color: ${props => props.theme.inputs.placeholder};
  }
  
  &.error {
    color: #B20000;
  }
`

const StyledPromocodeBtn = styled(Button)`
  height: 100%;
  width: 128px;
  font-size: 16px;
`

const StyledPromocodeText = styled.p`
  margin-top: 8px;
  padding-left: 16px;
  color: #636366;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const LoyaltyCardRow = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 13px 16px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 17px;
  line-height: 1;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
  
  &.opened {
    padding: 13px 16px 4px;
  }
`

const LoyaltyCardInputBox = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
`

const StyledLoyaltyCardBtn = styled(Button)`
  height: 100%;
  width: 128px;
  font-size: 16px;
  margin-right: -12px;
`

const LoyaltyCardInput = styled.input`
  margin-top: 8px;
  flex: 1;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
  color: ${props => props.theme.inputs.color};
  background: transparent;
  font-weight: 400;
  font-size: 17px;

  &::placeholder {
    color: ${props => props.theme.inputs.placeholder};
  }
  
  &.error {
    color: #B20000;
  }
`